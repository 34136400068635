import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { verifyEmail } from "http/authApi";
import { Box, Stack, styled } from "@mui/material";
import SoftInput from "components/SoftInput";
import * as yup from "yup";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

const VerificationInput = styled(SoftInput)(({ theme }) => ({
  fontSize: "1.5625rem",
  fontWeight: "600",
  backgroundColor: "transparent !important",
  input: { textAlign: "center ", padding: "0px !important" },
  appearance: "textfield",
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    appearance: "none",
    margin: 0,
  },
}));
const schema = yup
  .array()
  .required()
  .of(yup.number().required())
  .when("$length", (len, schema) => {
    if (len) return schema.length(len);
    else return schema;
  });

const VerifyEmail = ({ email, setNewPassword }) => {
  const location = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(Array(6).fill(""));

  const { mutate, isError } = useMutation((formData) => verifyEmail(formData), {
    onSuccess: (response, formData) => {
      if (response?.data?.verified) {
        setNewPassword(true);
      }
      return setIsValid(true);
    },
    onError: (error) => {
      console.log(error);
      setError(error.response.data.message);
    },
  });

  const update = useCallback((index, val) => {
    return setCode((prevState) => {
      const slice = prevState.slice();
      slice[index] = val;
      return slice;
    });
  }, []);

  const formRef = useRef(null);

  function handleKeyDown(evt) {
    const index = parseInt(evt.currentTarget.dataset.index);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput = form.querySelector(`.input-${prevIndex}`);
    const nextInput = form.querySelector(`.input-${nextIndex}`);
    switch (evt.key) {
      case "Backspace":
        if (code[index]) update(index, "");
        else if (prevInput) prevInput.select();
        break;
      case "ArrowRight":
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case "ArrowLeft":
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  }

  function handleChange(evt) {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    let nextIndex = index + 1;
    let nextInput = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || "");
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split("");
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  }

  function handleFocus(evt) {
    evt.currentTarget.select();
  }

  useEffect(() => {
    if (isSubmitted) {
      try {
        setIsValid(schema.isValidSync(code, { context: { length } }));
      } catch (e) {}
    }
  }, [code]);

  async function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitted(true);
    try {
      const res = await mutate({ code: code?.join(""), email: email, type: "forgot-password" });
      if (res?.verified == false) {
        setIsValid(true);
      }
    } catch (e) {
      console.log(e);
      setIsValid(false);
    }
  }
  return (
    <SoftBox p={8}>
      <SoftBox display="flex" justifyContent="center">
        <SoftTypography
          variant="label"
          color="warning"
          fontWeight="regular"
          textGradient
          mb={3}
          textAlign="center"
        >
          Enter Verification Code
        </SoftTypography>
      </SoftBox>
      <SoftBox
        component="form"
        role="form"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Box
          ref={formRef}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Stack component={"fieldset"} border={"none"} direction={"row"} spacing={1.2}>
            {code.map((value, i) => (
              <VerificationInput
                key={i}
                value={value}
                required
                placeholder="0"
                error={error || isValid}
                sx={{
                  width: "50px !important",
                  padding: "12px 0px !important",
                  color: (isValid && "red") || "#000000",
                }}
                inputProps={{
                  type: "number",
                  className: `input-${i}`,
                  "aria-label": `Number ${i + 1}`,
                  "data-index": i,
                  pattern: "[0-9]*",
                  inputtype: "numeric",
                  onChange: handleChange,
                  onKeyDown: handleKeyDown,
                  onFocus: handleFocus,
                }}
              />
            ))}
          </Stack>
          <SoftBox mt={1}>
            <SoftTypography color="error" variant="caption" fontWeight="light">
              {error}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={1} mb={1} alignSelf="flex-end" justifyContent="flex-end">
            <SoftButton type="submit" variant="gradient" color="warning">
              next
            </SoftButton>
          </SoftBox>
        </Box>
      </SoftBox>
    </SoftBox>
  );
};

VerifyEmail.propTypes = {
  email: PropTypes.string,
  setNewPassword: PropTypes.any,
};

export default VerifyEmail;
