import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@mui/material";

const CloseBtn = ({ handleCloseMenu }) => {
  return (
    <Icon
      sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
        fontSize: `${size.md} !important`,
        fontWeight: `${fontWeightBold} !important`,
        stroke: dark.main,
        strokeWidth: "2px",
        cursor: "pointer",
        mt: 2,
      })}
      onClick={handleCloseMenu}
    >
      close
    </Icon>
  );
};

CloseBtn.propTypes = {
  handleCloseMenu: PropTypes.any,
};

export default CloseBtn;