// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// React base styles
import colors from "assets/theme/base/colors";

const IUsers = ({ color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={colors[color] ? colors[color].main : colors.dark.main}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 485.541 485.541"
    >
      <g>
        <g id="XMLID_1854_">
          <path
            id="XMLID_1882_"
            d="M136.475,105.77c-42.1,0-76.3,34.1-76.3,76.3s34.1,76.3,76.3,76.3s76.3-34.1,76.3-76.3
			S178.575,105.77,136.475,105.77z M136.475,157.17c-13.7,0-24.9,11.1-24.9,24.9c0,6.3-5.1,11.4-11.4,11.4s-11.4-5.1-11.4-11.4
			c0-26.3,21.4-47.6,47.6-47.6c6.3,0,11.4,5.1,11.4,11.4S142.675,157.17,136.475,157.17z"
          />
          <path
            id="XMLID_1874_"
            d="M349.075,53.77c-42.1,0-76.3,34.1-76.3,76.3s34.1,76.3,76.3,76.3s76.3-34.1,76.3-76.3
			S391.275,53.77,349.075,53.77z M349.075,105.17c-13.7,0-24.9,11.1-24.9,24.9c0,6.3-5.1,11.4-11.4,11.4s-11.4-5.1-11.4-11.4
			c0-26.3,21.4-47.6,47.6-47.6c6.3,0,11.4,5.1,11.4,11.4C360.475,100.07,355.375,105.17,349.075,105.17z"
          />
          <path
            id="XMLID_1879_"
            d="M485.475,309.07v-1.6c0-0.1,0-0.2,0-0.2c-0.7-21.5-4.7-36.3-26.7-50.1c-29.1-18.3-61.6-34.1-61.6-34.1
			l-25.9,81.8l-15.1-30.4c26.5-37-1.9-38.9-6.9-38.9l0,0c0,0-0.1,0-0.2,0s-0.1,0-0.2,0l0,0c-5.1,0-33.5,1.9-6.9,38.9l-15.1,30.4
			l-25.9-81.8c0,0-32.5,15.8-61.6,34.1c-6.7,4.2-11.7,8.5-15.5,13.1c10.5,5.7,22.5,12.6,34.1,19.9c14.1,8.9,23.8,19.2,29.7,31.6
			c5.8,12.3,7.1,24.9,7.4,35.7c0.1,0.7,0.1,1.4,0.1,2.1v1.3c0.1,4.4,0.1,8.9,0,13.5c0,0.3,0,0.5,0,0.8v4.6h53.7h136.5v-59.2
			c0,1,0,1.6,0,1.6C485.575,317.57,485.575,313.17,485.475,309.07z M485.275,306.87C485.275,306.47,485.275,306.47,485.275,306.87
			L485.275,306.87z"
          />
          <g id="XMLID_1878_">
            <g id="XMLID_1881_">
              <path
                d="M272.775,361.07v-1.6c0-0.1,0-0.2,0-0.2c-0.6-21.5-4.7-36.3-26.7-50.1c-29.1-18.3-61.6-34.1-61.6-34.1l-25.9,81.8
					l-15.1-30.4c26.5-37-1.9-38.9-6.9-38.9l0,0c0,0-0.1,0-0.2,0s-0.1,0-0.2,0l0,0c-5.1,0-33.5,1.9-6.9,38.9l-15.1,30.4l-25.9-81.8
					c0,0-32.5,15.8-61.6,34.1c-21.9,13.8-25.9,28.6-26.6,50.1c0,0.1,0,0.1,0,0.2v1.6c-0.1,4.1-0.1,8.5,0,13.1c0,0,0-0.6,0-1.6v59.2
					h136.2h136.5v-59.2c0,1,0,1.6,0,1.6C272.875,369.57,272.875,365.17,272.775,361.07z"
              />
            </g>
            <g id="XMLID_1880_">
              <path d="M0.275,358.87C0.275,358.47,0.275,358.47,0.275,358.87C0.275,358.97,0.275,358.87,0.275,358.87z" />
            </g>
            <g id="XMLID_1851_">
              <path d="M272.575,358.97C272.575,358.47,272.575,358.47,272.575,358.97C272.575,358.87,272.575,358.97,272.575,358.97z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

// Setting default values for the props of Shop
IUsers.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Users
IUsers.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default IUsers;
