import React, { useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import * as moment from "moment";
import * as qs from "qs";

// React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "examples/Tables/Table";

// Data
import basicAuthData from "layouts/basicAuth/data/basicAuthData";
import { getAllBasicAuths } from "http/basicAuthApi";
import { useQuery } from "react-query";
import { useSoftUIController } from "context";
import BasicAuthDelete from "./components/BasicAuthDelete";
import BasicAuthCreate from "./components/BasicAuthCreate";

const BasicAuth = () => {
  const [controller, dispatch] = useSoftUIController();
  const { userRole } = controller;
  const { skeletonRows, columns } = basicAuthData();
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({
    queryMeta: {
      paginate: true,
      limit: limit,
      page: 1,
    },
    filterMeta: {},
  });
  const [page, setPage] = useState(1);

  const { isLoading, data: basicAuths } = useQuery(["basicAuths", page], () => getAllBasicAuths(qs.stringify(query)));

  const basicAuthsTableRowData = basicAuths?.data.map((basicAuth, index) => ({
    id: ++index,
    uuid: basicAuth.uuid,
    username: basicAuth.username,
    createdAt: moment(basicAuth.createdAt).format("DD.MM.YYYY_HH:mm:ss"),
    actions: userRole === "superAdmin" && (
      <SoftBox display="flex" alignItems="center" gap={1.5}>
        <BasicAuthDelete uuid={basicAuth?.uuid} />
      </SoftBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Basic Auth Candidats</SoftTypography>
              <BasicAuthCreate />
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table 
                minHeight={limit == 20 ? 1180 : limit == 5 ? 500 : 660}
                pagination
                columns={columns}
                pageCount={Math.ceil(basicAuths?.meta?.count / limit)}
                setPage={setPage}
                currentPage={page}
                setQuery={setQuery}
                limit={limit}
                rows={isLoading ? skeletonRows : basicAuthsTableRowData}
                />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default BasicAuth;
