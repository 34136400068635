import React from "react";
import SoftBox from "components/SoftBox";
import SoftModal from "components/SoftModal";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { registerUser } from "http/authApi";
import { useMutation, useQueryClient } from "react-query";

const UserCreate = () => {
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const queryClient = useQueryClient()

  const { mutate, isError } = useMutation((formData) => registerUser(formData), {
    onSuccess: (response, formData) => {
      queryClient.invalidateQueries('realusers');
      setOpen(false);
    },
    onError: (error) => {
      console.log(error);
      // setError(error.response.data.message);
    },
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    const user = { email: email, password: password };

    await mutate(user);
  };

  return (
    <SoftBox>
      <SoftButton variant="outlined" color="warning" onClick={handleOpen}>
        Add User
      </SoftButton>
      <SoftModal title="Edit User" open={open} handleClose={handleClose}>
        <SoftBox component="form" role="form" onSubmit={submitHandler}>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Email
              </SoftTypography>
            </SoftBox>
            <SoftInput type="email" required placeholder="Email" id="email" name="email" />
          </SoftBox>
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                id="password"
                name="password"
              >
                Password
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              id="password"
              name="password"
              required
              icon={{
                component: showPassword ? (
                  <Visibility
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <VisibilityOff
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  />
                ),
                direction: "right",
              }}
            />
          </SoftBox>
          <SoftBox mt={4} mb={1}>
            <SoftButton type="submit" variant="gradient" color="warning">
              Create
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftModal>
    </SoftBox>
  );
};

export default UserCreate;
