import moment from "moment";

const sortByMonth = (item) => {
  return moment(item.month, "YYYY-MM").format("MMMM");
};

const sortByWeek = (item) => {
  const weekStartDate = moment(item.week, "YYYY-WW").startOf("isoweek");
  const weekEndDate = moment(item.week, "YYYY-WW").endOf("isoweek");
  const weekName = `${weekStartDate.format("MMMM D")} - ${weekEndDate.format("MMMM D, YYYY")}`;
  return weekName;
};

const sortByDay = (item) => {
  const dayDate = moment(item.day, "YYYY-MM-DD");
  const dayName = dayDate.format("dddd, MMMM D, YYYY");
  return dayName;
};
function filterAndFormat(
  transaction = {
    completedData: [],
    pendingData: [],
    failedData: [],
    stopedData: [],
  },
  type
) {
  const allMonths = [
    ...transaction?.completedData,
    ...transaction?.pendingData,
    ...transaction?.failedData,
    ...transaction?.stopedData,
  ]?.map((item) =>
    type === "month" ? sortByMonth(item) : type === "week" ? sortByWeek(item) : sortByDay(item)
  );

  const uniqueMonths = [...new Set(allMonths)];

  return uniqueMonths;
}

function calculatePercentageChange(arr = []) {
  if (arr.length < 2) {
    return null; // Not enough data to calculate percentage change
  }

  const lastItem = arr[arr.length - 1]?.count;
  const secondToLastItem = arr[arr.length - 2]?.count;

  const change = lastItem - secondToLastItem;
  const percentageChange = (change / Math.abs(secondToLastItem)) * 100;
  // const res = {
  //   percent: `${percentageChange.toFixed(2)}%`,
  // };

  // if (percentageChange < 0) {
  //   res.color = "error";
  // } else {
  //   res.color = "success";
  // }
  return `${percentageChange.toFixed(2)}%`;
}

const generateChartData = ({ data, label, color, renderKey, type }) => {
  const labels = data?.transaction?.[renderKey]?.map((item) =>
    type === "month" ? sortByMonth(item) : type === "week" ? sortByWeek(item) : sortByDay(item)
  );
  const datasets = {
    label,
    color,
    data: data?.transaction?.[renderKey]?.map((item) => item.count),
  };
  return { labels, datasets };
};

export { filterAndFormat, calculatePercentageChange, generateChartData };
