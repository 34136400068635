import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const CompliteCurrently = ({ value, onChangeCompliteValue }) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChangeCompliteValue}
      defaultValue={value}
      aria-required
      disablePortal
      disableClearable
      sx={{ width: 80 }}
      options={["AMD", "RUB"]}
      renderInput={(params) => <TextField {...params} placeholder="AMD" />}
    />
  );
};

CompliteCurrently.propTypes = {
  onChangeCompliteValue: PropTypes.func,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default CompliteCurrently;