import { $authHost } from "./index";

const getAllBasicAuths = async (query) => {
  const { data } = await $authHost.get(`/worker/api/v1/basicAuth?${query}`);
  return data;
};

const getOneBasicAuth = async (uuid) => {
  const { data } = await $authHost.get(`/worker/api/v1/basicAuth/${uuid}`);
  return data;
};

const createBasicAuth = async ({ username, password }) => {
  const { data } = await $authHost.post(`/worker/api/v1/basicAuth`, { username, password });
  return data;
};
const deleteOneBasicAuth = async (uuid) => {
  const { data } = await $authHost.delete(`/worker/api/v1/basicAuth/${uuid}`);
  return data;
};

export { getAllBasicAuths, getOneBasicAuth, deleteOneBasicAuth, createBasicAuth };
