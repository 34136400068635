// React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";

// React icons
import Shop from "examples/Icons/Shop";
import Office from "examples/Icons/Office";
import Document from "examples/Icons/Document";
import Users from "layouts/users";
import Verification from "layouts/authentication/Verification";
import Authentication from "layouts/authentication";
import Transactions from "layouts/transactions";
import IUsers from "examples/Icons/IUsers";
import ITransaction from "examples/Icons/ITransaction";
import UserEditPage from "layouts/users/components/UserEditPage";
import BasicAuth from "layouts/basicAuth";
import TransactionUUID from "layouts/transactionUUID";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  { type: "title", title: "Fora to EasyPay", key: "Fora to EasyPay" },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    route: "/transactions",
    icon: <ITransaction size="12px" />,
    component: <Transactions />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Transactions",
    key: "transactions",
    route: "/transactions/:uuid",
    icon: <ITransaction size="12px" />,
    component: <TransactionUUID />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "BasicAuth",
    key: "basicAuth",
    route: "/basicAuth",
    icon: 'assured_workload_icon',
    component: <BasicAuth />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Transactions",
    key: "transactions/:uuid",
    route: "/users/:uuid",
    icon: <ITransaction size="12px" />,
    component: <UserEditPage />,
    noCollapse: true,
  },
  { type: "title", title: "User Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <IUsers size="12px" />,
    component: <Users />,
    noCollapse: true,
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: <Document size="12px" />,
    component: <Authentication />,
    noCollapse: true,
  },
  {
    type: "",
    name: "verify",
    key: "verify",
    route: "/authentication/verify",
    icon: <Document size="12px" />,
    component: <Verification />,
    noCollapse: true,
  },
];

export default routes;
