import React from "react";
import moment from "moment";
import qs from "qs";
import { Grid, Tab, Tabs } from "@mui/material";
import { getAnalytic } from "http/infoApi";

// React components
import SoftBox from "components/SoftBox";

// React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Data
import { useQuery } from "react-query";
import {
  calculatePercentageChange,
  filterAndFormat,
  generateChartData,
} from "layouts/dashboard/helper/chart-helper";

const Dashboard = () => {
  const [sortByTime, setSortByTime] = React.useState("month");
  const [status, setStatus] = React.useState(4);
  const statusTabs = ["Completed", "Pending", "Failed", "Stoped", "All"];

  const [value, setValue] = React.useState(2);
  const handleChange = (__, newValue) => {
    setValue(newValue);
  };
  const { isLoading, data: analytic } = useQuery(["analytic", sortByTime], () =>
    getAnalytic(
      qs.stringify({
        time: sortByTime,
      })
    )
  );

  const transactionsLineChartData = {
    labels: filterAndFormat(analytic?.transaction, sortByTime),
    datasets: [
      (statusTabs?.[status] === "Completed" || statusTabs?.[status] === "All") && {
        label: "Completed",
        color: "success",
        data: analytic?.transaction?.completedData?.map((item) => item.totalAmount),
      },
      (statusTabs?.[status] === "Pending" || statusTabs?.[status] === "All") && {
        label: "Pending",
        color: "warning",
        data: analytic?.transaction?.pendingData?.map((item) => item.totalAmount),
      },
      (statusTabs?.[status] === "Failed" || statusTabs?.[status] === "All") && {
        label: "Failed",
        color: "error",
        data: analytic?.transaction?.failedData?.map((item) => item.totalAmount),
      },
      (statusTabs?.[status] === "Stoped" || statusTabs?.[status] === "All") && {
        label: "Stopped",
        color: "error",
        data: analytic?.transaction?.stopedData?.map((item) => item.totalAmount),
      },
    ],
  };

  const transactionRes = analytic?.transaction?.transactionRes?.data?.[0];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Transacted Amount, Today" }}
                count={transactionRes?.totalAmountForDay}
                currency={true}
                percentage={{ color: "success" }}
                icon={{ color: "warning", component: "payment_icon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                currency={true}
                title={{ text: "Total Transacted Amount" }}
                count={transactionRes?.totalAmount}
                percentage={{ color: "success" }}
                icon={{ color: "warning", component: "payment_icon" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Total Number of Transactions" }}
                count={transactionRes?.completedCount}
                percentage={{ color: "error" }}
                icon={{ color: "success", component: "emoji_events" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "Users" }}
                count={analytic?.user}
                percentage={{ color: "success" }}
                icon={{
                  color: "error",
                  component: "people_icon",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="success"
                title={`Successful Transactions, ${
                  calculatePercentageChange(analytic?.transaction?.completedData) || ""
                }`}
                // description={calculatePercentageChange(analytic?.transaction?.completedData)}
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Complited data Count",
                  color: "success",
                  renderKey: "completedData",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="warning"
                title={`Pending Transactions, ${
                  calculatePercentageChange(analytic?.transaction?.pendingData) || ""
                }`}
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Pending data Count",
                  color: "warning",
                  renderKey: "pendingData",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="error"
                title={`Failed Transactions, ${
                  calculatePercentageChange(analytic?.transaction?.failedData) || ""
                }`}
                // description={calculatePercentageChange(analytic?.transaction?.failedData)}
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Failed data Count",
                  color: "error",
                  renderKey: "failedData",
                  type: sortByTime,
                })}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <ReportsBarChart
                color="error"
                title={`Stopped Transactions, ${
                  calculatePercentageChange(analytic?.transaction?.stopedData) || ""
                }`}
                date="just updated"
                chart={generateChartData({
                  data: analytic,
                  label: "Stoped data Count",
                  color: "error",
                  renderKey: "stopedData",
                  type: sortByTime,
                })}
              />
            </Grid>
          </Grid>
        </SoftBox>

        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <GradientLineChart
                title="Volume of Transactions"
                filters={
                  <SoftBox display="flex" alignItems="center" justifyContent="space-between" pb={2}>
                    <Tabs value={status} onChange={(___, newValue) => setStatus(newValue)}>
                      {statusTabs.map((tab, index) => (
                        <Tab key={index} label={tab} disableRipple />
                      ))}
                    </Tabs>

                    <Tabs onChange={handleChange} value={value} color="warning">
                      <Tab onClick={() => setSortByTime("day")} label={`DAY`} />
                      <Tab onClick={() => setSortByTime("week")} label="WEEK" />
                      <Tab onClick={() => setSortByTime("month")} label="MONTH" />
                    </Tabs>
                  </SoftBox>
                }
                height="auto"
                chart={transactionsLineChartData}
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Dashboard;
