import React, { useState } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useSoftUIController } from "context";
import * as qs from "qs";

import { Card, Icon, IconButton } from "@mui/material";
import Table from "examples/Tables/Table";

// example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import UserDelete from "./components/UserDelete";
import UserCreate from "./components/UserCreate";

import usersTableData from "./data/usersTableData";
import { getUsers } from "http/userApi";
import { useNavigate } from "react-router-dom";

const Users = () => {
  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const { skeletonRows, columns } = usersTableData();
  const { userRole } = controller;
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({
    queryMeta: {
      paginate: true,
      limit: limit,
      page: 1,
    },
    filterMeta: {},
  });
  const [page, setPage] = useState(1);
  // const limit = 12;

  const { isLoading, data: users } = useQuery(["realusers", page], () => getUsers(qs.stringify(query)));

  const usertableRowData = users?.data.map((user) => ({
    userId: user.userId,
    ["email"]: user.email,
    uuid: user.uuid,
    createdAt: moment(user.createdAt).format("HH:mm, DD.MM.YYYY"),
    ["roles"]: JSON.stringify(user.roles),
    [" "]: userRole === "superAdmin" && (
      <SoftBox display="flex" alignItems="center" gap={2} justifyContent="space-between">
        {/* <UserEdit uuid={user.uuid} /> */}
        <IconButton size="small" onClick={() => navigate(`/users/${user.uuid}`)}>
          <Icon fontSize="small" color="success">
            edit_icon
          </Icon>
        </IconButton>
        <UserDelete uuid={user.uuid} />
      </SoftBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h4" color="warning" fontWeight="bold" textGradient>
                Users Table
              </SoftTypography>
              {userRole === "superAdmin" && <UserCreate />}
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                pagination
                minHeight={limit == 20 ? 1180 : limit == 5 ? 500 : 660}
                columns={columns} 
                pageCount={Math.ceil(users?.meta?.count / limit)}
                rows={isLoading ? skeletonRows : usertableRowData}
                setPage={setPage}
                currentPage={page}
                setQuery={setQuery}
                limit={limit}
              />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

export default Users;
