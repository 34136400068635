// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";

// React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import SyncProblemOutlinedIcon from "@mui/icons-material/SyncProblemOutlined";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
function Breadcrumbs({ icon, title, route, light, status }) {
  const routes = route.slice(0, -1);

  return (
    <SoftBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/">
          <SoftTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </SoftTypography>
        </Link>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <SoftTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </SoftTypography>
          </Link>
        ))}
        <SoftBox display="flex" alignItems="center" gap={3}>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            sx={{ lineHeight: 0 }}
          >
            {title.replace("-", " ")}
          </SoftTypography>
        </SoftBox>
      </MuiBreadcrumbs>
      {(status && (
        <SoftBox display="flex" alignItems="center" gap={1} mt={1}>
          <SoftTypography
            fontWeight="bold"
            textTransform="capitalize"
            variant="h6"
            color={
              status?.toLowerCase() === "completed"
                ? "success"
                : status?.toLowerCase() === "pending"
                ? "warning"
                : "error"
            }
            noWrap
          >
            Transaction {status}
          </SoftTypography>
          {status?.toLowerCase() === "completed" ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : status?.toLowerCase() === "pending" ? (
            <SyncProblemOutlinedIcon color="warning" />
          ) : status?.toLowerCase() === "failed" ? (
            <SmsFailedOutlinedIcon color="error" />
          ) : (
            <StopCircleOutlinedIcon color="error" />
          )}
        </SoftBox>
      )) || (
        <SoftTypography
          fontWeight="bold"
          textTransform="capitalize"
          variant="h6"
          color={light ? "white" : "dark"}
          noWrap
        >
          {title.replace("-", " ")}
        </SoftTypography>
      )}
    </SoftBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
  status: "",
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
  status: PropTypes.string,
};

export default Breadcrumbs;
