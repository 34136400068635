import { useCallback, useContext, useEffect } from "react";
import { Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import { getProfile } from "http/userApi";
import { useSoftUIController, setUserRole, AuthContext } from "context";

// components
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import ILogOut from "examples/Icons/ILogOut";

export const AccountPopover = (props) => {
  const [controller, dispatch] = useSoftUIController();
  const authContext = useContext(AuthContext);
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate();
  const { isLoading, data: user } = useQuery("userprofile", () => getProfile());

  const handleSignOut = useCallback(() => {
    onClose?.();
    authContext.logout();
  }, [onClose, navigate]);

  useEffect(() => {
    if (!isLoading && user) {
      setUserRole(dispatch, user.roles[0]);
    }
  }, [isLoading, user]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200, backgroundColor: "white !important" } }}
    >
      <SoftBox
        sx={{
          py: 0.3,
          px: 1,
          mb: 2,
        }}
      >
        <SoftTypography variant="overline">Email</SoftTypography>
        <SoftTypography color="text" variant="body2">
          {!isLoading && user?.email} {"  "}
        </SoftTypography>
      </SoftBox>
      {/* <SoftButton variant="gradient" color="warning" onClick={handleSignOut}>
        <SoftTypography variant="p" color="white" mr={1}>
          Sign out
        </SoftTypography>
        <ILogOut color="white" size="18px" />
      </SoftButton> */}
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
