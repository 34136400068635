// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// React base styles
import colors from "assets/theme/base/colors";

const ITransaction = ({ color, size }) => {
  return (
    <svg
      width={size}
      height={size}
      fill={colors[color] ? colors[color].main : colors.dark.main}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 491.521 491.521"
    >
      <g>
        <g>
          <path
            d="M482.554,275.355H381.932c-20.944,0-37.922,16.978-37.922,37.922c0,20.944,16.978,37.922,37.922,37.922h100.622
			c2.64,0,4.78-2.14,4.78-4.78v-66.283C487.333,277.495,485.193,275.355,482.554,275.355z M384.138,331.248
			c-9.926,0-17.971-8.046-17.971-17.971s8.046-17.97,17.971-17.97s17.971,8.046,17.971,17.97
			C402.109,323.203,394.063,331.248,384.138,331.248z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M381.934,259.771h100.378v-97.426c0-20.788-16.853-37.641-37.641-37.641h-9.292H58.751h-0.006
			c-30.131,0-54.558-24.428-54.558-54.559v383.733c0,20.791,16.852,37.643,37.641,37.643h402.842
			c20.788,0,37.641-16.852,37.641-37.643v-87.094H381.934c-29.504,0-53.51-24.002-53.51-53.507S352.43,259.771,381.934,259.771z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M411.935,0H72.847C42.716,0,18.289,24.426,18.289,54.56c0,30.132,24.426,54.559,54.558,54.559h376.633V37.546
			C449.481,16.809,432.671,0,411.935,0z M370.599,62.351H83.662c-4.304,0-7.793-3.486-7.793-7.793s3.489-7.793,7.793-7.793h286.936
			c4.304,0,7.793,3.486,7.793,7.793S374.903,62.351,370.599,62.351z"
          />
        </g>
      </g>
    </svg>
  );
};

// Setting default values for the props of Shop
ITransaction.defaultProps = {
  color: "dark",
  size: "16px",
};

// Typechecking props for the Users
ITransaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "white",
  ]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ITransaction;
