import { $authHost } from "http";
import { $host } from "http";

const login = async (data) => {
  return await $host.post("/auth/api/v1/auth/login", data);
};

const loginBy2FA = async (data, oauthToken) => {
  return await $host.post("/auth/api/v1/auth/2fa/authenticate", data, {
    headers: {
      Authorization: `Bearer ${oauthToken}`
    }
  });
};

const registerUser = async (data) => {
  return await $authHost.post("/auth/api/v1/auth/register", data);
};

const checkEmailAdmin = async (data) => {
  return await $authHost.patch("/auth/api/v1/auth/checkEmail", data);
};

const verifyEmail = async (data) => {
  return await $authHost.post("/auth/api/v1/auth/verifyEmail", data);
};

const newPassword = async (data) => {
  return await $authHost.put("/auth/api/v1/auth/newPassword", data);
};

const forgotTwoFa = async (data) => {
  return await $host.post("/auth/api/v1/auth/2fa/forgot", data);
};

export { forgotTwoFa,login, loginBy2FA, registerUser, checkEmailAdmin, verifyEmail,newPassword };
