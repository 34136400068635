import React, { useEffect, useState } from "react";
import { setLayout } from "context";
import SignIn from "./sign-in";
import Verification from "./Verification";
import { useSoftUIController } from "context";
import { constants } from "utils/constants";

const Authentication = () => {
  const [controller, dispatch] = useSoftUIController();
  const { succesLogin } = controller;
  useEffect(() => {
    setLayout(dispatch, "auth");
  }, []);
  return (
    <div>
      {succesLogin === "signin" ? (
        <SignIn />
      ) : (
        <Verification />
      )}
    </div>
  );
};

export default Authentication;
