import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const AutoCompliteRole = ({ value, onChangeCompliteValue }) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChangeCompliteValue}
      defaultValue={value}
      aria-required
      disablePortal
      disableClearable
      sx={{ width: "100%" }}
      options={["admin", "superAdmin"]}
      renderInput={(params) => <TextField {...params} placeholder="Role" />}
    />
  );
};

AutoCompliteRole.propTypes = {
  onChangeCompliteValue: PropTypes.func,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default AutoCompliteRole;