import { $authHost } from "./index";

const getAllTransactions = async (query) => {
  const { data } = await $authHost.get(`/payment/api/v1/transaction?${query}`);
  return data;
};

const getOneTransaction = async (uuid) => {
  const { data } = await $authHost.get(`/payment/api/v1/transaction/${uuid}`);
  return data;
};

const updateOneTransaction = async ({uuid, inputDto}) => {
  const { data } = await $authHost.patch(`/payment/api/v1/transaction/${uuid}`, inputDto);
  return data;
};

const deleteOneTransaction = async (uuid) => {
  const { data } = await $authHost.delete(`/payment/api/v1/transaction/${uuid}`);
  return data;
};

export { getAllTransactions, getOneTransaction, deleteOneTransaction, updateOneTransaction };