import { useEffect, useRef, useState, useCallback, useContext } from "react";
import { Box, Input, Stack, styled } from "@mui/material";
import * as yup from "yup";

// import Countdown from "../components/Countdown";
import { useLocation } from "react-router-dom";
import { useMutation } from "react-query";
// components
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import CoverLayout from "../components/CoverLayout";
import QRCodeModal from "./components/QRCodeModal";

// import { verifyContact } from "http/userApi";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { loginBy2FA } from "http/authApi";
import { useSoftUIController, AuthContext } from "context";
import SoftTypography from "components/SoftTypography";
import { forgotTwoFa } from "http/authApi";
import { setSuccessLogin } from "context";

const VerificationInput = styled(Input)(({ theme }) => ({
  fontSize: "1.5625rem",
  fontWeight: "600",
  color: "#000000",

  input: { textAlign: "center ", padding: "0px !important" },
  appearance: "textfield",
  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    appearance: "none",
    margin: 0,
  },
}));

const schema = yup
  .array()
  .required()
  .of(yup.number().required())
  .when("$length", (len, schema) => {
    if (len) return schema.length(len);
    else return schema;
  });

const Verification = () => {
  const authContext = useContext(AuthContext);

  const [controller, dispatch] = useSoftUIController();
  const { succesLogin, oauthToken } = controller;
  const location = useLocation();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState(Array(6).fill(""));

  const { mutate, isError } = useMutation((formData) => loginBy2FA(formData, oauthToken), {
    onSuccess: (response, formData) => {
      if (response?.data.token) {
        authContext.login(response?.data.token);
      }
      setSuccessLogin(dispatch, {succesLogin: "signin", oauthToken: null});
      return setIsValid(true);
    },
    onError: (error) => {
      console.log(error);
      setError(error.response.data.message);
    },
  });

  const forgotTwoFaHandler = useMutation((formData) => forgotTwoFa(formData), {
    onSuccess: (response, formData) => {},
    onError: (error) => {
      console.log(error);
      setError(error.response.data.message);
    },
  });

  const update = useCallback((index, val) => {
    return setCode((prevState) => {
      const slice = prevState.slice();
      slice[index] = val;
      return slice;
    });
  }, []);

  const formRef = useRef(null);

  function handleKeyDown(evt) {
    const index = parseInt(evt.currentTarget.dataset.index);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    const prevIndex = index - 1;
    const nextIndex = index + 1;
    const prevInput = form.querySelector(`.input-${prevIndex}`);
    const nextInput = form.querySelector(`.input-${nextIndex}`);
    switch (evt.key) {
      case "Backspace":
        if (code[index]) update(index, "");
        else if (prevInput) prevInput.select();
        break;
      case "ArrowRight":
        evt.preventDefault();
        if (nextInput) nextInput.focus();
        break;
      case "ArrowLeft":
        evt.preventDefault();
        if (prevInput) prevInput.focus();
    }
  }

  function handleChange(evt) {
    const value = evt.currentTarget.value;
    const index = parseInt(evt.currentTarget.dataset.index);
    const form = formRef.current;
    if (isNaN(index) || form === null) return; // just in case

    let nextIndex = index + 1;
    let nextInput = form.querySelector(`.input-${nextIndex}`);

    update(index, value[0] || "");
    if (value.length === 1) nextInput?.focus();
    else if (index < length - 1) {
      const split = value.slice(index + 1, length).split("");
      split.forEach((val) => {
        update(nextIndex, val);
        nextInput?.focus();
        nextIndex++;
        nextInput = form.querySelector(`.input-${nextIndex}`);
      });
    }
  }

  function handleFocus(evt) {
    evt.currentTarget.select();
  }

  useEffect(() => {
    if (isSubmitted) {
      try {
        setIsValid(schema.isValidSync(code, { context: { length } }));
      } catch (e) {}
    }
  }, [code]);

  async function handleSubmit(evt) {
    evt.preventDefault();
    setIsSubmitted(true);
    try {
      await mutate({ code: code?.join(""), email: location?.state?.email });
    } catch (e) {
      console.log(e);
      setIsValid(false);
    }
  }

  return (
    <CoverLayout title="2-Step Verification" description="" image={curved9}>
      {succesLogin === "qrcode" && <QRCodeModal />}
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <Box ref={formRef}>
          <Stack component={"fieldset"} border={"none"} direction={"row"} spacing={1.2}>
            {code.map((value, i) => (
              <VerificationInput
                key={i}
                value={value}
                required
                placeholder="0"
                error={error || isValid}
                sx={{
                  width: "50px !important",
                  border: "none !important",
                  padding: "10px 0px !important",
                  color: (isValid && "red") || "#000000",
                }}
                inputProps={{
                  type: "number",
                  className: `input-${i}`,
                  "aria-label": `Number ${i + 1}`,
                  "data-index": i,
                  pattern: "[0-9]*",
                  inputtype: "numeric",
                  onChange: handleChange,
                  onKeyDown: handleKeyDown,
                  onFocus: handleFocus,
                }}
              />
            ))}
          </Stack>
          <SoftBox mt={1}>
            <SoftTypography color="error" variant="caption" fontWeight="light">
              {error}
            </SoftTypography>
          </SoftBox>
        </Box>
        <SoftTypography
          variant="caption"
          sx={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => forgotTwoFaHandler.mutate({ email: location?.state?.email })}
        >
          Forgot 2Fa
        </SoftTypography>
        <SoftBox mt={3} mb={1}>
          <SoftButton type="submit" variant="gradient" color="warning">
            next
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
};

export default Verification;
