import { useContext, useRef, useState } from "react";

// React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import curved9 from "assets/images/curved-images/curved-6.jpg";
import { login } from "http/authApi";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { setSuccessLogin } from "context";
import { useSoftUIController } from "context";
import { setQrCode } from "context";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

const SignIn = () => {
  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const captchaRef = useRef(null);

  const [credentialsErros, setCredentialsError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(null);
  const [captchaToken, setCaptchaToken] = useState("");
  
  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (email.trim().length === 0 || !email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }

    if (password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    const user = { email: email, password: password };
    try {
      const response = await login(user);
      navigate("/authentication/sign-in", { state: { email: email } });
      if (!!response?.data?.token) {
        setSuccessLogin(dispatch, {succesLogin: "verificationCode", oauthToken: response?.data?.token});
        // setGoogleAuthToken(dispatch, response?.data?.token)
      } else {
        setQrCode(dispatch, response?.data);
        setSuccessLogin(dispatch, {succesLogin: "qrcode", oauthToken: null});

      }
    } catch (res) {
      if (res.hasOwnProperty("message")) {
        setCredentialsError(res?.response?.data?.message);
      } else {
        setCredentialsError(res?.errors[0]?.detail);
      }
    }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
      });
    };
  };

  return (
    <CoverLayout
      title="Welcome back"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={submitHandler}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            required
            error={credentialsErros}
            placeholder="Email"
            id="email"
            name="email"
          />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              id="password"
              name="password"
            >
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            id="password"
            name="password"
            required
            error={credentialsErros}
            icon={{
              component: showPassword ? (
                <Visibility
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityOff
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                />
              ),
              direction: "right",
            }}
          />
        </SoftBox>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={"6LeY0VsnAAAAANm37LleA6Mrxua1GuKyuJC-3XrT"}
          onChange={(value) => setCaptchaToken(value)}
        />
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={!captchaToken}>
            sign in
          </SoftButton>
        </SoftBox>
      </SoftBox>
    </CoverLayout>
  );
};

export default SignIn;
