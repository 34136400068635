import React from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";

const DateToDate = ({ dateFrom, dateTo, setDateFrom, setDateTo }) => {
  return (
    <SoftBox mb={0.5}>
      <SoftBox mb={0.5} ml={0.5}>
        <SoftTypography component="label" variant="caption" fontWeight="bold">
          Date
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" alignItems="center" gap={0.5}>
        <SoftInput
          type="datetime-local"
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
        />
        <SoftTypography component="label" variant="caption" fontWeight="bold">
          -
        </SoftTypography>
        <SoftInput
          type="datetime-local"
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
        />
      </SoftBox>
    </SoftBox>
  );
};

DateToDate.propTypes = {
  dateFrom: PropTypes.any,
  setDateFrom: PropTypes.any,
  dateTo: PropTypes.any,
  setDateTo: PropTypes.any,
};

export default DateToDate;
