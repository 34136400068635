import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { Card } from "@mui/material";
import PropTypes from "prop-types";

import FormInput from "layouts/transactions/components/FormInput";
import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import AutoCompliteRole from "./components/AutoCompliteRole";
import NewPassword from "./components/AutoCompliteRole/NewPassword";
import VerifyEmail from "./components/AutoCompliteRole/VerifyEmail";

import { updateUserByUuid, getUserByUuid } from "http/userApi";
import { checkEmailAdmin } from "http/authApi";

const UserEditPage = () => {
  const { uuid } = useParams();
  const queryClient = useQueryClient();
  useEffect(() => {}, [uuid]);

  const { isLoading, data: user } = useQuery(["userByUuid", uuid], () => getUserByUuid(uuid));
  const [email, setEmail] = React.useState(user?.email);
  const [role, setRole] = React.useState(user?.roles?.[0]);
  const [forgotPassword, setForgotPassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(false);

  const { mutate, isError } = useMutation(
    ({ uuid, inputDto }) => updateUserByUuid({ uuid, inputDto }),
    {
      onSuccess: (response, formData) => {
        queryClient.invalidateQueries("realusers");
        setOpen(false);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: checkEmail } = useMutation((inputDto) => checkEmailAdmin(inputDto), {
    onSuccess: (response, formData) => {
      setForgotPassword(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateUser = async (uuid, inputDto) => {
    await mutate({ uuid, inputDto });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            {forgotPassword && !newPassword ? (
              <VerifyEmail
                email={email || (!isLoading && user?.email)}
                setNewPassword={setNewPassword}
              />
            ) : newPassword ? (
              <NewPassword email={email || (!isLoading && user?.email)} />
            ) : (
              <>
                <SoftBox display="flex" alignItems="center" justifyContent="center" p={3}>
                  <SoftTypography variant="h4" color="warning" fontWeight="bold" textGradient>
                    Edit User
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center">
                  <SoftBox p={3} sx={{ width: "60%" }}>
                    <FormInput
                      name={"email"}
                      placeholder={"email"}
                      value={email || (!isLoading && user?.email)}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <SoftBox mt={1} display="flex" alignItems="center" flexDirection="column">
                      <AutoCompliteRole
                        value={role || (!isLoading && user.roles[0])}
                        onChangeCompliteValue={(event, newValue) => {
                          setRole(newValue);
                        }}
                      />
                      <SoftTypography
                        variant="h6"
                        sx={{
                          fontSize: "14px",
                          fontWeight: 300,
                          color: "blue",
                          textDecoration: "underline",
                          alignSelf: "flex-end",
                          cursor: "pointer",
                          marginTop: "5px",
                        }}
                        onClick={(e) => {
                          checkEmail({ email: email });
                        }}
                      >
                        Forgot Password
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" justifyContent="flex-end" py={2}>
                      <SoftButton
                        variant="gradient"
                        color="warning"
                        onClick={() =>
                          updateUser(uuid, {
                            email: email,
                            roles: [role],
                          })
                        }
                      >
                        Update
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
};

UserEditPage.propTypes = {
  details: PropTypes.object,
  uuid: PropTypes.string,
};

export default UserEditPage;
