import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField } from "@mui/material";

const CompliteCommand = ({ value, onChangeCompliteValue }) => {
  return (
    <Autocomplete
      value={value}
      onChange={onChangeCompliteValue}
      defaultValue={value}
      aria-required
      disablePortal
      disableClearable
      sx={{ width: 120 }}
      options={["check", "pay"]}
      renderInput={(params) => <TextField {...params} placeholder="Limit" />}
    />
  );
};

CompliteCommand.propTypes = {
  onChangeCompliteValue: PropTypes.func,
  value: PropTypes.any,
  isLoading: PropTypes.bool,
};

export default CompliteCommand;