import { Skeleton } from "@mui/material";
import SoftBox from "components/SoftBox";
import { generateData } from "utils/tableColumnData";

const data = () => {
  return {
    columns: [
      { name: "id", accessor: "id", align: "center" },
      { name: "uuid", accessor: "uuid", align: "center" },
      { name: "username", accessor: "username", align: "center" },
      { name: "createdAt", accessor: "createdAt", align: "center" },
      { name: "actions", accessor: "actions", align: "center" },
    ],
    skeletonRows: generateData(10, {
      id: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={40} />,
      uuid: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={40} />,
      username: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={60} />,
      createdAt: <Skeleton variant="text" sx={{ fontSize: "13px" }} width={100} />,
      actions: (
        <SoftBox display="flex" alignItems="center" gap={1}>
          <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />
          <Skeleton variant="text" sx={{ fontSize: "13px" }} width={70} />
        </SoftBox>
      ),
    }),
  };
};

export default data;