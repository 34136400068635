function formatNumberWithCommas(number) {
  // Convert the number to a string
  let numberStr = number.toString();

  // Split the string into integer and decimal parts
  let parts = numberStr.split(".");

  // Extract the integer part and add commas for thousands separators
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Rejoin the integer and decimal parts with a dot (.) and return the result
  return parts.join(".");
}

export { formatNumberWithCommas };