import React from "react";
import PropTypes from "prop-types";

// Mui Components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// Components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  p: "72px 21px 41px 21px",
  borderRadius: 5,
  color: "white"
};

const TransitionsModal = ({ title, open, handleOpen, handleClose, children }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      style={{ border: "none" }}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <SoftBox
          sx={style}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          position="relative"
          gap={7}
        >
          <SoftBox
            alignSelf="flex-end"
            style={{ cursor: "pointer", position: "absolute", top: "10px", right: "21px" }}
            onClick={handleClose}
          >
            x
          </SoftBox>
          <SoftTypography
            variant="h4"
            component="h4"
            textAlign="center"
            alignSelf="center"
            sx={{ color: "rgba(0, 0, 0, 0.85)" }}
            style={{ fontWeight: "600" }}
          >
            {title}
          </SoftTypography>
          <SoftBox display="flex" alignItems="center" gap={2} alignSelf="center">
            {children}
          </SoftBox>
        </SoftBox>
      </Fade>
    </Modal>
  );
};
TransitionsModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  handleOpen: PropTypes.any,
  handleClose: PropTypes.any,
  children: PropTypes.any,
};
export default TransitionsModal;
