import { $authHost } from "./index";

const getUsers = async (query) => {
  const { data } = await $authHost.get(`/user/api/v1/user?${query}`);
  return data;
};

const getUserByUuid = async (uuid) => {
  const { data } = await $authHost.get(`/user/api/v1/user/${uuid}`);
  return data;
};

const updateUserByUuid = async ({uuid, inputDto}) => {
  const { data } = await $authHost.patch(`/user/api/v1/user/${uuid}`, inputDto);
  return data;
};

const deleteUserById = async (uuid) => {
  const { data } = await $authHost.delete(`/user/api/v1/user/${uuid}`);
  return data;
};

const getProfile = async () => {
  const { data } = await $authHost.get(`/user/api/v1/user/me`);
  return data;
};

export { getUsers, getProfile, updateUserByUuid, deleteUserById, getUserByUuid };
