import React, { useState } from "react";
import PropTypes from "prop-types";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SoftButton from "components/SoftButton";
import { newPassword } from "http/authApi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import TransitionsModal from "components/TransitionsModal";

const NewPassword = ({ email }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { mutate, isError } = useMutation((formData) => newPassword(formData), {
    onSuccess: (response, formData) => {
      if (response?.data?.changed) {
        setOpen(true);
      }
    },
    onError: (error) => {
      console.log(error);
      setError(error.response.data.message);
    },
  });

  return (
    <SoftBox mb={2} p={8}>
      <TransitionsModal
        title="Your password has been successfully changed"
        open={open}
        handleOpen={() => setOpen(true)}
        handleClose={() => setOpen(false)}
      >
        <SoftButton
          color="warning"
          style={{ backgroundColor: "#FFAC30", borderRadius: "24px" }}
          onClick={() => {
            navigate("/users");
            setOpen(false);
          }}
        >
          <SoftTypography
            ml={0.5}
            component="span"
            variant="outlined"
            sx={{ color: "rgba(0, 0, 0, 0.85)" }}
            fontWeight="bold"
          >
            Go to Log in page
          </SoftTypography>
        </SoftButton>
      </TransitionsModal>
      <SoftBox mb={1} ml={0.5}>
        <SoftTypography
          variant="label"
          color="warning"
          fontWeight="medium"
          textGradient
          mb={2}
          textAlign="center"
        >
          New Password
        </SoftTypography>
      </SoftBox>
      <SoftInput
        type={showPassword ? "text" : "password"}
        placeholder="Password"
        id="password"
        name="password"
        required
        // error={credentialsErros}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        icon={{
          component: showPassword ? (
            <Visibility
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={() => setShowPassword(!showPassword)}
            />
          ) : (
            <VisibilityOff
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={() => setShowPassword(!showPassword)}
            />
          ),
          direction: "right",
        }}
      />
      <SoftBox display="flex" justifyContent="flex-end" mt={3}>
        <SoftButton
          variant="gradient"
          color="warning"
          onClick={() =>
            mutate({
              email: email,
              password: password,
            })
          }
        >
          Change
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
};

NewPassword.propTypes = {
  email: PropTypes.string,
  handleClose: PropTypes.any,
};

export default NewPassword;
